@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600;700&family=Karla:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


* {
  margin: 0;
  padding: 0;

}

body,
html {
  overflow-x: hidden;



}


.npf_wgts iframe {
  height: 100% !important;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a,
ul,
ol,
dl,
li,
strong,
b,
sup,
sub,
input,
label,
abbr,
address,
article,
button,
header,
footer,
section,
small,
table,
td,
th,
tr,
textarea {
  font-family: 'Poppins', sans-serif !important;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1260px !important;
  }
}


section.main-element.activesection {
  position: relative;
  top: 0;
  display: inline-block;
  padding-top: 100px !important;
  width: 100%;
}



.default-chatbot-indicator {
  display: none !important;
}

.default-chatbot-indicator.active {
  display: block !important;
}

::-webkit-scrollbar {
  width: 5px;
  border-radius: 15px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 15px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 15px;
}

::-webkit-scrollbar-thumb:hover {
  background: red;
  border-radius: 15px;
}


.overviewtxt a {
  color: red;
}

a.btn.btn-primary {
  background: red;
  color: #fff;
  border-color: red;
  padding: 12px 25px;
}